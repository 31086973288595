import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { Icon } from '@iconify-icon/react';

const propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'modern']),
  className: PropTypes.string,
};
const defaultProps = {
  isActive: false,
  isDisabled: false,
  placeholder: null,
  value: null,
  onClear: null,
  variant: 'default',
  className: null,
};

const DateRangePickerInputTrigger = forwardRef(
  (
    { placeholder, value, isActive, isDisabled, setIsOpen, onClear, variant, className, ...props },
    ref,
  ) => {
    function handleOnFocus() {
      setIsOpen(true);
    }

    return (
      <Popover.Anchor>
        <div
          className={classNames(
            `react-datepicker-input-trigger react-datepicker-input-trigger--${variant}`,
            { 'react-datepicker-input-trigger--active': isActive },
          )}
        >
          <div className="input-group">
            <FormControl
              type="text"
              placeholder={placeholder}
              className={
                variant === 'default' ? 'clear-readonly' : 'form-control--modern form-control--sm'
              }
              value={value}
              readOnly
              disabled={isDisabled}
              autoComplete="off"
              onFocus={handleOnFocus}
            />

            {!!value && onClear && (
              <button
                className="react-datepicker-input-trigger__clear"
                type="button"
                disabled={isDisabled}
                onClick={onClear}
              >
                <em className="icon-cancel-2" />
              </button>
            )}

            <span className="input-group-btn">
              <button
                ref={ref}
                type="button"
                className={
                  variant === 'default'
                    ? 'btn btn-default btn-default-light'
                    : 'btn form-control-button'
                }
                {...props}
              >
                <Icon icon="clarity:calendar-line" inline width="16" />
              </button>
            </span>
          </div>
        </div>
      </Popover.Anchor>
    );
  },
);

DateRangePickerInputTrigger.propTypes = propTypes;
DateRangePickerInputTrigger.defaultProps = defaultProps;

export default DateRangePickerInputTrigger;
