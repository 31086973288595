import type { DateTime } from 'luxon';
import { DATE_TIME_INTERVAL } from '@/date/constants';

export default function getDateObjectRoundedToInterval(
  dateObject: DateTime,
  interval = DATE_TIME_INTERVAL,
): DateTime {
  return dateObject.set({
    minute: Math.ceil(dateObject.get('minute') / interval) * interval,
  });
}
