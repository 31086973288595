import { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { getDateObject } from '@/date';
import getDateFormatHour from '@/date/getDateFormatHour';
import getDateObjectRoundedToInterval from '@/date/getDateObjectRoundedToInterval';
import isMatchTimeInterval from '@/date/isMatchTimeInterval';
import { DATE_TIME_INTERVAL } from '@/date/constants';
import StoryChief from '@/storychief';

const propTypes = {
  date: PropTypes.shape({}),
  minTime: PropTypes.shape({}),
  maxTime: PropTypes.shape({}),
  caption: PropTypes.string,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
const defaultProps = {
  date: undefined,
  minTime: undefined,
  maxTime: undefined,
  caption: null,
  isClearable: false,
  className: null,
  onChange: () => {},
};

function DateRangePickerTimeInput({
  date,
  minTime,
  maxTime,
  caption,
  isClearable,
  className,
  onChange,
}) {
  // Variables
  const format = getDateFormatHour();
  const startOfDay = getDateObject().set({ hour: 0, minute: 0 }).toJSDate();
  const endOfDay = getDateObject().set({ hour: 23, minute: 59 }).toJSDate();

  // State
  const [tempDate, setTempDate] = useState(null);

  // Functions
  function handleOnChange(changedValue) {
    const dateObject = getDateObject(changedValue);

    if (isClearable && !changedValue) {
      onChange(null);
      setTempDate(null);
    } else if (!isMatchTimeInterval(dateObject)) {
      onChange(dateObject.toFormat('HH:mm'));
      setTempDate(null);
    } else {
      setTempDate(dateObject);
    }
  }

  function handleOnBlur() {
    if (!tempDate) return;

    onChange(getDateObjectRoundedToInterval(tempDate).toFormat('HH:mm'));
    setTempDate(null);
  }

  // Render
  return (
    <>
      <DatePicker
        selected={date}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={DATE_TIME_INTERVAL}
        dateFormat={format}
        timeFormat={format}
        isClearable={isClearable}
        timeCaption={caption || StoryChief.timezone}
        minTime={minTime || startOfDay}
        maxTime={maxTime || endOfDay}
        className={className}
      />
    </>
  );
}

DateRangePickerTimeInput.propTypes = propTypes;
DateRangePickerTimeInput.defaultProps = defaultProps;

export default DateRangePickerTimeInput;
