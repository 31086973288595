import { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import * as Popover from '@radix-ui/react-popover';
import { enUS } from 'date-fns/locale';
import StoryChief from '@/storychief';
import getDateObject from '@/date/getDateObject';
import isSameDay from '@/date/isSameDay';
import { formatDate } from '@/date';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';
import getDateObjectRoundedToInterval from '@/date/getDateObjectRoundedToInterval';
import Button from '@/storychief/components/Button';
import DateRangePickerTimeInput from './DateRangePickerTimeInput';
import DateRangePickerInputTrigger from './DateRangePickerInputTrigger';

const propTypes = {
  children: PropTypes.node,
  container: PropTypes.shape({}),
  startDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  isSelectsRange: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  align: PropTypes.oneOf(['start', 'center', 'end']),
  triggerType: PropTypes.oneOf(['button', 'input', 'inline']),
  triggerClassName: PropTypes.string,
  inputTriggerPlaceholder: PropTypes.string,
  inputTriggerFormat: PropTypes.string,
  inputTriggerVariant: PropTypes.oneOf(['default', 'modern']),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

const defaultProps = {
  children: null,
  container: undefined,
  isSelectsRange: false,
  isDisabled: false,
  showTimeInput: true,
  startDate: null,
  triggerType: 'button',
  endDate: undefined,
  minDate: undefined,
  maxDate: undefined,
  side: 'top',
  align: 'center',
  inputTriggerPlaceholder: undefined,
  inputTriggerFormat: null,
  inputTriggerVariant: 'default',
  triggerClassName: undefined,
  onClear: undefined,
};

// Set the first day of the week
enUS.options.weekStartsOn = StoryChief.firstDayWeek;

const DEFAULT_MAX_PAST_DATES = 10;
const DEFAULT_MAX_FUTURE_DATES = 5;

function DateRangePicker({
  children,
  container,
  isSelectsRange,
  showTimeInput,
  startDate,
  endDate,
  minDate,
  maxDate,
  triggerType,
  inputTriggerPlaceholder,
  inputTriggerFormat,
  inputTriggerVariant,
  side,
  align,
  onChange,
  onClear,
  isDisabled,
  triggerClassName,
}) {
  // Variables
  const fixedStartDate = startDate ? getFixedDateObject(startDate) : getDefaultStartDate();
  const fixedEndDate = endDate ? getFixedDateObject(endDate) : undefined;
  const fixedMinDate = minDate
    ? getFixedDateObject(minDate)
    : getDateObjectWithTimezone().minus({ years: DEFAULT_MAX_PAST_DATES });
  const fixedMaxDate = maxDate
    ? getFixedDateObject(maxDate)
    : getDateObjectWithTimezone().plus({ years: DEFAULT_MAX_FUTURE_DATES });
  const isApplyDateOnSelect = !showTimeInput && !isSelectsRange;

  // State
  const [isOpen, setIsOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);

  // Functions
  function getDefaultStartDate() {
    return getDateObjectRoundedToInterval(getDateObjectWithTimezone());
  }

  function getFixedDateObject(date) {
    if (typeof date === 'string') {
      return getDateObjectWithTimezone(date);
    }

    return date;
  }

  function getSingleDateReturn(value) {
    const date = replaceDateTimezone(value, StoryChief.timezone).toJSDate();

    return getDateObjectWithTimezone(date);
  }

  function getMultiDateReturnObject(value) {
    const newStartDate = replaceDateTimezone(value[0], StoryChief.timezone).toJSDate();
    const newEndDate = replaceDateTimezone(value[1], StoryChief.timezone).toJSDate();

    return {
      startDate: getDateObjectWithTimezone(newStartDate),
      endDate: getDateObjectWithTimezone(newEndDate),
    };
  }

  function handleOnChange(value) {
    if (isApplyDateOnSelect) {
      setIsOpen(false);
      onChange(getSingleDateReturn(value));
    } else if (isSelectsRange) {
      if (tempStartDate === null || tempEndDate !== null) {
        // When the user selects the start date, only set a temporary state and don't trigger the callback.
        setTempStartDate(getAdjustedChangedDate(value[0]));
        setTempEndDate(null);
      } else {
        // When the user selects the end date, trigger the callback and reset the temporary state.
        setTempEndDate(getAdjustedChangedDate(value[1]));
      }
    } else {
      setTempStartDate(getAdjustedChangedDate(value));
    }
  }

  // Adjust the date value if it is outside the allowed date range.
  function getAdjustedChangedDate(value) {
    const formattedValue = getSingleDateReturn(value);

    if (formattedValue < fixedMinDate) {
      return getJSDateInLocalTimezone(fixedMinDate);
    }

    if (formattedValue > fixedMaxDate) {
      return getJSDateInLocalTimezone(fixedMaxDate);
    }

    return value;
  }

  function replaceDateTimezone(date, timeZone) {
    if (timeZone) {
      return getDateObject(date).setZone(timeZone, {
        keepLocalTime: true,
        keepCalendarTime: true,
      });
    }

    return getDateObject(date);
  }

  function handleOnOpenChange(value) {
    setIsOpen(value);

    if (!value) {
      clearTempState();
    }
  }

  function getJSDateInLocalTimezone(date) {
    return getDateObjectWithTimezone(date)
      .setZone('system', {
        keepLocalTime: true,
        keepCalendarTime: true,
      })
      .toJSDate();
  }

  function getMinTime() {
    if (fixedMinDate && isSameDay(tempStartDate || fixedStartDate, fixedMinDate)) {
      return getJSDateInLocalTimezone(fixedMinDate);
    }

    return undefined;
  }

  function getMaxTime() {
    if (
      fixedMaxDate &&
      // Compare the date depending on which one the user is currently viewing in the UI.
      isSameDay(tempEndDate || fixedEndDate || tempStartDate || fixedStartDate, fixedMaxDate)
    ) {
      return getJSDateInLocalTimezone(fixedMaxDate);
    }

    return undefined;
  }

  function clearTempState() {
    setTempStartDate(null);
    setTempEndDate(null);
  }

  function handleOnApply() {
    if (isSelectsRange) {
      // The user may want their date range to be 1 day. In that case, only the start date will be set.
      // To simplify the component's API, we set the end date to the start date.
      onChange(getMultiDateReturnObject([getStartDate(), getEndDate() || getStartDate()]));
    } else {
      onChange(getSingleDateReturn(getStartDate()));
    }

    setIsOpen(false);
    clearTempState();
  }

  function getStartDate() {
    return tempStartDate || getJSDateInLocalTimezone(fixedStartDate);
  }

  function getEndDate() {
    if (tempStartDate) {
      return tempEndDate;
    }

    if (fixedEndDate) {
      return getJSDateInLocalTimezone(fixedEndDate);
    }

    return undefined;
  }

  function getInputTriggerValue() {
    return startDate ? formatDate(fixedStartDate, inputTriggerFormat || 'MMMM dd, yyyy') : '';
  }

  function renderDatePicker() {
    return (
      <DatePicker
        selectsRange={isSelectsRange}
        startDate={getStartDate()}
        endDate={getEndDate()}
        selected={tempStartDate || getJSDateInLocalTimezone(fixedStartDate)}
        minDate={fixedMinDate ? getJSDateInLocalTimezone(fixedMinDate) : undefined}
        maxDate={fixedMaxDate ? getJSDateInLocalTimezone(fixedMaxDate) : undefined}
        showMonthDropdown
        showYearDropdown
        useShortMonthInDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={DEFAULT_MAX_FUTURE_DATES + DEFAULT_MAX_PAST_DATES}
        customTimeInput={
          showTimeInput ? (
            <DateRangePickerTimeInput minTime={getMinTime()} maxTime={getMaxTime()} />
          ) : undefined
        }
        onChange={handleOnChange}
        showTimeInput={showTimeInput}
        locale={enUS}
        inline
        calendarClassName={triggerType === 'inline' ? 'react-datepicker--inline' : undefined}
      />
    );
  }

  // Render
  if (triggerType === 'inline') {
    return renderDatePicker();
  }

  return (
    <Popover.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <Popover.Trigger
        asChild={triggerType === 'input'}
        disabled={isDisabled}
        className={classNames('react-datepicker-trigger', triggerClassName)}
      >
        {triggerType === 'input' ? (
          <DateRangePickerInputTrigger
            isDisabled={isDisabled}
            isActive={isOpen}
            setIsOpen={setIsOpen}
            value={getInputTriggerValue()}
            placeholder={inputTriggerPlaceholder}
            onClear={onClear}
            variant={inputTriggerVariant}
          />
        ) : (
          children
        )}
      </Popover.Trigger>
      <Popover.Portal container={container}>
        <Popover.Content
          className="dropdown-menu dropdown-menu--react-datepicker"
          side={side}
          align={align}
          sideOffset={5}
          collisionPadding={{
            top: 200 /* Prefer the dropdown to be positioned below its trigger. */,
          }}
        >
          {renderDatePicker()}

          {!isApplyDateOnSelect && (
            <div className="react-datepicker-footer">
              <Button size="xs" onClick={handleOnApply}>
                Apply
              </Button>
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

DateRangePicker.propTypes = propTypes;
DateRangePicker.defaultProps = defaultProps;

export default DateRangePicker;
